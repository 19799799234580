	
	// General

	$color-text-general: #4c4c4c !default;
	$color-text-headings: #4c4c4c !default;

	$color-text-accent-1: #4c4c4c !default;		// black
	$color-text-accent-2: #ffbe00 !default;		// yellow

	$color-border-general: #cccccc !default;
	$color-background-general: #e6e6e6 !default;
	
	// Forms

	$color-text-forms: #4c4c4c !default;
	$color-text-forms-placeholders: #dddddd !default;

	$color-border-forms: #cccccc !default;
	$color-border-forms-focus: #4c4c4c !default;
	$color-border-forms-invalid: #f1b7be !default;

	$color-background-forms: #ffffff !default;
	$color-background-forms-disabled: #e6e6e6 !default;

	$color-text-forms-button: #ffffff !default;
	$color-text-forms-button-hover: #ffffff !default;
	$color-text-forms-button-active: #ffffff !default;
	
	$color-border-forms-button: #4c4c4c !default;
	$color-border-forms-button-hover: #ffbe00 !default;
	$color-border-forms-button-active: #ffbe00 !default;
	
	$color-background-forms-button: #4c4c4c !default;
	$color-background-forms-button-hover: #ffbe00 !default;
	$color-background-forms-button-active: #ffbe00 !default;
	
	// Links

	$color-text-link: #4c4c4c !default;
	$color-text-link-hover: #4c4c4c !default;
	$color-text-link-active: #4c4c4c !default;

	// Body
	
	$color-background-body: #F2F2F2 !default;
	
	// Header

	$color-background-header: #F2F2F2 !default;

	// Content
	
	$color-background-content: #F2F2F2 !default;
	
	// Footer

	$color-background-footer: transparent !default;
	$color-headlines-footer: #F2F2F2 !default;
	$color-text-footer: #F2F2F2 !default;

	// Section
	
	$color-background-general-section: #F2F2F2 !default;
	$color-background-general-section-overlay: rgba(0, 0, 0, 0.5) !default;
	
	// Themes - Contrast

	$color-text-theme-contast-general: #ffffff !default; 
	$color-text-theme-contast-headings: #ffffff !default;						
	
	$color-border-theme-contast-general: rgba(255, 255, 255, 0.25) !default;
	
	$color-text-theme-contast-link: #ffffff !default;
	$color-text-theme-contast-link-hover: #ffffff !default;
	$color-text-theme-contast-link-active: #ffffff !default;

	// Borders
	
	$border-width-thin: 1px !default;
	$border-width-medium: 3px  !default;
	$border-width-thick: 5px !default;
	
	// Spacing
	
	$spacing-xx-small: 12.5px !default;
	$spacing-x-small: 17.5px !default;
	$spacing-small: 35px !default;
	$spacing-medium: 70px !default;
	$spacing-large: 105px !default;
	$spacing-xl-large: 140px !default;
	$spacing-xxl-large: 175px !default;
	
	// Partner and client list Spacing
	
	$spacing-partner-none: 0 !default;
	$spacing-partner-line: 1px !default;
	$spacing-partner-small: 10px !default;
	$spacing-partner-medium: 20px !default;
	$spacing-partner-large: 30px !default;
	
	// Section spacings
	
	$spacing-section-none: 0px !default;
	$spacing-section-xx-small: 10px !default;
	$spacing-section-x-small: 20px !default;
	$spacing-section-small: 40px !default;
	$spacing-section-medium: 80px !default;
	$spacing-section-large: 120px !default;
	
	