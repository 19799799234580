/**
 * 	Name: novapan.css
 *	
 */
/* ==========================================================================
   =Reset and Normalize
   ========================================================================== */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
	 * 1. Correct the line height in all browsers.
	 * 2. Prevent adjustments of font size after orientation changes in
	 *    IE on Windows Phone and in iOS.
	 */
html {
  line-height: 1;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
	 * 1. Add the correct box sizing in Firefox.
	 * 2. Show the overflow in Edge and IE.
	 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
	 * 1. Remove the gray background on active links in IE 10.
	 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
	 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
	 * Hide the overflow in IE.
	 */
svg:not(:root) {
  overflow: hidden;
}

/**
	 * 1. Correct color not being inherited.
	 *    Known issue: affects color of disabled elements.
	 * 2. Correct font properties not being inherited.
	 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
	 * Show the overflow in IE.
	 * 1. Show the overflow in Edge.
	 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
	 * Remove the inheritance of text transform in Edge, Firefox, and IE.
	 * 1. Remove the inheritance of text transform in Firefox.
	 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
	 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
	 *    controls in Android 4.
	 * 2. Correct the inability to style clickable types in iOS and Safari.
	 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

/**
	 * 1. Correct the text wrapping in Edge and IE.
	 * 2. Correct the color inheritance from `fieldset` elements in IE.
	 * 3. Remove the padding so developers are not caught out when they zero out
	 *    `fieldset` elements in all browsers.
	 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
	 * Remove the default vertical scrollbar in IE.
	 */
textarea {
  overflow: auto;
}

/**
	 * 1. Add the correct box sizing in IE 10-.
	 * 2. Remove the padding in IE 10-.
	 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
	 * Correct the cursor style of increment and decrement buttons in Chrome.
	 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
	 * 1. Correct the odd appearance in Chrome and Safari.
	 * 2. Correct the outline style in Safari.
	 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
	 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
	 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
	 * 1. Correct the inability to style clickable types in iOS and Safari.
	 * 2. Change font properties to `inherit` in Safari.
	 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/*
	 * Add the correct display in all browsers.
	 */
summary {
  display: list-item;
}

/**
	 * Add the correct display in IE.
	 */
template {
  display: none;
}

/**
	 * Add the correct display in IE 10-.
	 */
[hidden] {
  display: none;
}

/* ==========================================================================
   =Typography
   ========================================================================== */
/**
 	 * 1. We are setting the base size in px so we can make easy calculations for rem units https://css-tricks.com/rems-ems/
	 *    How to set html font-size : HTML FONT SIZE = DESIRED FONT SIZE / 1.5 
 	 */
html {
  font-size: 10px;
  /* 1 */
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: italic;
  color: #4c4c4c;
  background-color: #F2F2F2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  color: #4c4c4c;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  letter-spacing: 0;
}

h1 {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 30px;
  line-height: 36px;
}

h2 {
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 24px;
  line-height: 28px;
}

h3 {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 24px;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
}

h5 {
  margin-top: 11px;
  margin-bottom: 11px;
  font-size: 14px;
  line-height: 18px;
}

h6 {
  margin-top: 6px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 14px;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

p {
  margin-bottom: 20px;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

small {
  font-size: 90%;
}

big {
  font-size: 125%;
}

sub {
  vertical-align: sub;
  font-size: 75%;
}

sup {
  vertical-align: super;
  font-size: 75%;
}

abbr[title] {
  border-bottom: 1px dotted #4c4c4c;
  cursor: help;
}

address {
  display: block;
  margin-bottom: 20px;
}

q {
  color: #4c4c4c;
  quotes: "\201C" "\201D" "\201C" "\201D";
}

q:before {
  content: open-quote;
}

q:after {
  content: close-quote;
}

blockquote {
  overflow: hidden;
  margin-bottom: 20px;
  color: #4c4c4c;
  font-family: 'Roboto Condensed', sans-serif;
  quotes: "" "" "" "";
}

blockquote:before {
  content: open-quote;
}

blockquote:after {
  content: close-quote;
}

blockquote > *:last-child {
  margin-bottom: 0;
}

blockquote cite {
  display: block;
  margin-top: 10px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}

kbd,
tt,
var,
samp,
code,
pre {
  font-family: monospace;
}

pre {
  display: block;
  overflow-x: auto;
  margin-bottom: 20px;
  white-space: pre-wrap;
}

mark,
ins {
  text-decoration: none;
}

s,
del {
  text-decoration: line-through;
}

/* ==========================================================================
   =Forms
   ========================================================================== */
fieldset {
  display: block;
}

label {
  display: block;
}

/**
	 * 1. IE input fix. 
	 */
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea {
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  /* 1 */
  padding: 7px 14px;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  background-color: #ffffff;
  color: #4c4c4c;
  -webkit-appearance: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="color"]:focus,
textarea:focus {
  border-color: #4c4c4c;
  outline: none;
}

input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="date"]:disabled,
input[type="datetime"]:disabled,
input[type="datetime-local"]:disabled,
input[type="month"]:disabled,
input[type="week"]:disabled,
input[type="email"]:disabled,
input[type="number"]:disabled,
input[type="search"]:disabled,
input[type="tel"]:disabled,
input[type="time"]:disabled,
input[type="url"]:disabled,
input[type="color"]:disabled,
textarea:disabled {
  cursor: not-allowed;
  background-color: #e6e6e6;
}

/*
	input[type="text"]:invalid,
	input[type="password"]:invalid,
	input[type="date"]:invalid,
	input[type="datetime"]:invalid,
	input[type="datetime-local"]:invalid,
	input[type="month"]:invalid,
	input[type="week"]:invalid,
	input[type="email"]:invalid,
	input[type="number"]:invalid,
	input[type="search"]:invalid,
	input[type="tel"]:invalid,
	input[type="time"]:invalid,
	input[type="url"]:invalid,
	input[type="color"]:invalid,
	textarea:invalid {
		border-color: $color-border-forms-invalid;
	}
	*/
input[type="text"][disabled],
input[type="text"][readonly],
fieldset[disabled] input[type="text"],
input[type="password"][disabled],
input[type="password"][readonly],
fieldset[disabled] input[type="password"],
input[type="date"][disabled],
input[type="date"][readonly],
fieldset[disabled] input[type="date"],
input[type="datetime"][disabled],
input[type="datetime"][readonly],
fieldset[disabled] input[type="datetime"],
input[type="datetime-local"][disabled],
input[type="datetime-local"][readonly],
fieldset[disabled] input[type="datetime-local"],
input[type="month"][disabled],
input[type="month"][readonly],
fieldset[disabled] input[type="month"],
input[type="week"][disabled],
input[type="week"][readonly],
fieldset[disabled] input[type="week"],
input[type="email"][disabled],
input[type="email"][readonly],
fieldset[disabled] input[type="email"],
input[type="number"][disabled],
input[type="number"][readonly],
fieldset[disabled] input[type="number"],
input[type="search"][disabled],
input[type="search"][readonly],
fieldset[disabled] input[type="search"],
input[type="tel"][disabled],
input[type="tel"][readonly],
fieldset[disabled] input[type="tel"],
input[type="time"][disabled],
input[type="time"][readonly],
fieldset[disabled] input[type="time"],
input[type="url"][disabled],
input[type="url"][readonly],
fieldset[disabled] input[type="url"],
input[type="color"][disabled],
input[type="color"][readonly],
fieldset[disabled] input[type="color"],
textarea[disabled],
textarea[readonly],
fieldset[disabled] textarea {
  cursor: not-allowed;
  background-color: #e6e6e6;
}

textarea[rows] {
  height: auto;
}

/**
	 *  1. Disallow resize out of parent
	 */
textarea {
  max-width: 100%;
  /* 1 */
}

/**
	 * 1. IE input fix.
	 */
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  /* 1 */
  padding: 7px 14px;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  background-color: #ffffff;
  color: #4c4c4c;
  -webkit-appearance: none;
}

select[multiple] {
  height: auto;
  padding: 14px 14px;
  line-height: 20px;
}

select:disabled {
  cursor: not-allowed;
}

select:focus {
  border-color: #4c4c4c;
}

input[type="range"] {
  width: 50%;
}

/**
	 * 1. Firefox fix for size. or else it overflows on 320px
	 */
input[type="file"] {
  font-size: 13px;
  /* 1 */
  height: auto;
  width: 100%;
  margin-bottom: 20px;
}

input[type="checkbox"],
input[type="radio"] {
  margin-bottom: 0;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 14px;
  vertical-align: baseline;
}

input[type="reset"],
input[type="submit"],
input[type="button"] {
  position: relative;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  display: inline-block;
  padding: 7px 18px;
  border: 1px solid #4c4c4c;
  margin-bottom: 20px;
  background-color: #4c4c4c;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type="reset"]:hover,
input[type="submit"]:hover,
input[type="button"]:hover,
input[type="reset"]:focus,
input[type="submit"]:focus,
input[type="button"]:focus {
  border: 1px solid #ffbe00;
  background-color: #ffbe00;
  color: #ffffff;
}

input[type="reset"]:active,
input[type="submit"]:active,
input[type="button"]:active {
  border-color: #ffbe00;
  background-color: #ffbe00;
  color: #ffffff;
}

/**
 	 * Placeholder text color -- selectors need to be separate to work.
 	 */
::-webkit-input-placeholder {
  color: #dddddd;
}

:-moz-placeholder {
  color: #dddddd;
}

/**
 	 * 1. Since FF19 lowers the opacity of the placeholder by default
 	 */
::-moz-placeholder {
  color: #dddddd;
  opacity: 1;
  /* 1 */
}

:-ms-input-placeholder {
  color: #dddddd;
}

/* ==========================================================================
   =Formatting
   ========================================================================== */
hr {
  height: 1px;
  border: 0;
  margin: 20px 0;
  background-color: #cccccc;
}

/* ==========================================================================
   =Lists
   ========================================================================== */
ul,
ol {
  margin-bottom: 20px;
  list-style-position: inside;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 20px;
}

ul {
  list-style-type: disc;
}

ul.square,
ul.square ul {
  list-style-type: square;
}

ol {
  list-style-type: decimal;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 20px;
}

/* ==========================================================================
   =Images
   ========================================================================== */
img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: middle;
}

/* ==========================================================================
   =Tables
   ========================================================================== */
table,
th,
td {
  border: 1px solid #cccccc;
}

/**
	 * 1. Prevents HTML tables from becoming too wide
	 */
table {
  width: 100%;
  border-width: 1px 0 0 1px;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
  table-layout: fixed;
  /* 1 */
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

caption {
  margin-bottom: 20px;
}

th {
  border-width: 0 1px 1px 0;
  font-weight: 700;
}

td {
  border-width: 0 1px 1px 0;
}

th,
td {
  padding: 10px;
}

/* ==========================================================================
   =Links
   ========================================================================== */
a {
  color: #4c4c4c;
  text-decoration: none;
  outline: 0;
  transition: color 0.15s;
}

a:focus {
  color: #4c4c4c;
}

a:hover {
  color: #4c4c4c;
}

a:active {
  color: #4c4c4c;
}

/* ==========================================================================
   =Components
   ========================================================================== */
/* =Button
   ========================================================================== */
.ewf-btn {
  position: relative;
  background-clip: padding-box;
  display: inline-block;
  padding: 10px 18px;
  border: none;
  margin-bottom: 20px;
  background-color: #4c4c4c;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
  -webkit-appearance: none;
}

.ewf-btn:focus,
.ewf-btn:hover {
  border-color: #ffbe00;
  background-color: #ffbe00;
  color: #ffffff;
}

.ewf-btn:active {
  border-color: #ffbe00;
  background-color: #ffbe00;
  color: #ffffff;
}

/* =Headline
   ========================================================================== */
.ewf-headline {
  text-align: center;
}

.ewf-headline__title {
  margin-bottom: 20px;
}

.ewf-headline__subtitle {
  max-width: 500px;
  margin: 0 auto 56px;
}

/* =Section
   ========================================================================== */
.ewf-section {
  position: relative;
  z-index: 1;
  padding: 80px 0;
  margin-bottom: 0;
  background: no-repeat center center;
  background-size: cover;
}

/*.ewf-section *:last-child { margin-bottom: 0; }*/
.ewf-section--spacing-none {
  padding: 0px 0;
}

.ewf-section--spacing-xx-small {
  padding: 10px 0;
}

.ewf-section--spacing-x-small {
  padding: 20px 0;
}

.ewf-section--spacing-small {
  padding: 40px 0;
}

.ewf-section--spacing-medium {
  padding: 80px 0;
}

.ewf-section--spacing-large {
  padding: 120px 0;
}

.ewf-section--parallax {
  background-attachment: fixed;
  background-position: top center;
}

.ewf-section__overlay-color {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.85;
}

.ewf-section__overlay-pattern {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ewf-section__video-background-yt,
.ewf-section__video-background-local {
  position: absolute;
  z-index: 2;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.ewf-section__video-background-local video {
  width: 100%;
  height: auto;
}

.ewf-section__content {
  position: relative;
  z-index: 3;
}

/* ==========================================================================
   =Gears
   ========================================================================== */
.ewf-gear-content {
  position: relative;
  height: 675px;
  margin-bottom: 35px;
}

.ewf-gear-content img {
  width: 300px;
}

.gear-parallax {
  height: 675px;
  width: 675px;
  position: absolute;
  top: 0;
  left: -175px;
}

.gear-parallax__group {
  position: relative;
  height: 685px;
  width: 685px;
  margin-left: -10px;
  margin-top: -10px;
  background: url("../../content/gear-mask.svg");
  background-repeat: no-repeat;
  background-size: 102%;
  background-position: center center;
}

.gear-parallax__layer {
  z-index: 4;
  height: 675px;
  width: 675px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35%;
}

.parallax-mirror {
  border-radius: 400px;
}

.item-novapan-online .gear-parallax__layer {
  background-image: url("../../content/branduri/novapan-online.svg");
}

.item-novapan-echipamente .gear-parallax__layer {
  background-image: url("../../content/branduri/novapan-echipamente.svg");
}

.item-cofetarul-istet .gear-parallax__layer {
  background-image: url("../../content/branduri/cofetarul-istet.svg");
}

.item-arta-alba .gear-parallax__layer {
  background-image: url("../../content/branduri/arta-alba.svg");
}

.item-clipband .gear-parallax__layer {
  background-image: url("../../content/branduri/clipband.svg");
}

.item-reductoare-rossi .gear-parallax__layer {
  background-image: url("../../content/branduri/rossi.svg");
}

@media (max-width: 992px) {
  /* #content .ewf-gear-small-deco { */
  /* margin-top:100px; */
  /* height: 675px; */
  /* width: 675px; */
  /* position: relative; */
  /* background-attachment: scroll, scroll, scroll; */
  /* background-position: center, center, center; */
  /* background-size: 100.2%, 35%, 190%; */
  /* } */
}

@media (max-width: 768px) {
  .ewf-gear-content {
    height: 455px;
    width: 435px;
    margin: auto;
  }
  .gear-parallax {
    height: 435px;
    width: 435px;
    left: 0;
    top: 0;
  }
  .gear-parallax__group {
    height: 455px;
    width: 455px;
    background-size: 97%;
  }
  .gear-parallax__layer {
    height: 455px;
    width: 455px;
  }
  #wrap .content-scroller {
    height: inherit;
  }
  #novapan-echipamente,
  #novapan-online,
  #cofetarul-istet,
  #arta-alba,
  #clipband,
  #reductoare-rossi {
    padding-bottom: 60px;
    text-align: center;
  }
}

@media (min-width: 440px) and (max-width: 540px) {
  .ewf-gear-content {
    height: 385px;
    width: 385px;
    margin: auto;
    margin-bottom: 25px;
  }
  .gear-parallax {
    height: 385px;
    width: 385px;
    left: 0;
    top: 0;
  }
  .gear-parallax__group {
    height: 405px;
    width: 405px;
    background-size: 97%;
  }
  .gear-parallax__layer {
    height: 405px;
    width: 405px;
  }
  #wrap .content-scroller {
    height: inherit;
  }
  #novapan-echipamente,
  #novapan-online,
  #cofetarul-istet,
  #arta-alba,
  #clipband,
  #reductoare-rossi {
    padding-bottom: 60px;
    text-align: center;
  }
}

@media (max-width: 440px) {
  .ewf-gear-content {
    height: 270px;
    width: 270px;
    margin: auto;
    margin-bottom: 25px;
  }
  .gear-parallax {
    height: 270px;
    width: 270px;
    left: 0;
    top: 0;
  }
  .gear-parallax__group {
    height: 290px;
    width: 290px;
    background-size: 97%;
  }
  .gear-parallax__layer {
    height: 290px;
    width: 290px;
  }
  #wrap .content-scroller {
    height: inherit;
  }
  #content-wrap .ewf-section--spacing-medium {
    padding: 60px 0;
  }
  #novapan-echipamente,
  #novapan-online,
  #cofetarul-istet,
  #arta-alba,
  #clipband,
  #reductoare-rossi {
    padding-bottom: 40px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ewf-gear-content {
    height: 335px;
  }
  .gear-parallax {
    height: 335px;
    width: 335px;
    left: 0;
  }
  .gear-parallax__group {
    height: 355px;
    width: 355px;
    background-size: 97%;
  }
  .gear-parallax__layer {
    height: 335px;
    width: 335px;
  }
  #wrap .content-scroller {
    height: inherit;
  }
  #novapan-echipamente,
  #novapan-online,
  #cofetarul-istet,
  #arta-alba,
  #clipband,
  #reductoare-rossi {
    padding-bottom: 60px;
  }
  #content .ewf-valign--center {
    align-items: flex-start !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .ewf-gear-content {
    height: 435px;
  }
  .gear-parallax {
    height: 435px;
    width: 435px;
    left: 0;
  }
  .gear-parallax__group {
    height: 455px;
    width: 455px;
  }
  .gear-parallax__layer {
    height: 435px;
    width: 435px;
  }
}

/* ==========================================================================
   =Layout
   ========================================================================== */
/* =Wrap
   ========================================================================== */
#wrap {
  position: relative;
  overflow: hidden;
}

body {
  background-color: #F2F2F2;
  background-position: right center;
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-size: 480px;
}

.content-wrap {
  background-position: right top;
  background-repeat: no-repeat space;
  background-attachment: scroll;
  background-size: 380px;
}

@media (min-width: 320px) and (max-width: 440px) {
  .content-wrap {
    width: 300px;
    margin: auto;
  }
}

@media (min-width: 440px) and (max-width: 540px) {
  .content-wrap {
    width: 420px;
    margin: auto;
  }
}

@media (min-width: 540px) and (max-width: 767px) {
  .content-wrap {
    width: 520px;
    margin: auto;
  }
}

@media (min-width: 768px) {
  body {
    background-image: none;
  }
  .content-wrap {
    margin: inherit;
    background-size: 350px;
    background-image: url("../../content/gear-half-gri.svg");
    background-position: right top;
  }
}

@media (min-width: 992px) {
  .content-wrap {
    background-size: 460px;
  }
}

@media (min-width: 1760px) {
  .content-wrap {
    background-image: none;
  }
  body {
    background-image: url("../../content/gear-half-gri.svg");
  }
}

/* ==========================================================================
   =Header Gear
   ========================================================================== */
#header-gear {
  position: absolute;
  bottom: -545px;
  left: -170px;
  width: 650px;
  height: 650px;
  z-index: 0;
}

#header-gear img {
  width: 100%;
  display: block;
  -webkit-animation: anim-header-gear-rotation 160s infinite linear;
}

@keyframes anim-header-gear-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/* =Header
   ========================================================================== */
#header-media {
  height: 465px;
  opacity: .6;
  border-radius: 0 0 600px 600px;
  background-image: url("../../content/header-media-mask.svg"), url("../../content/back-header-media.jpg");
  background-position: center center, center -100px;
  background-repeat: no-repeat;
  background-color: #F5EDD1;
  background-attachment: scroll, fixed;
  margin: 0 15px;
}

.header-logo-bar {
  padding: 0 0 40px 0;
}

.header-logo-bar p {
  line-height: 16px;
  min-height: 55px;
  padding-top: 10px;
}

.header-logo-bar a {
  transition: opacity .2s;
  display: flex;
  text-align: center;
  min-height: 70px;
  align-content: center;
}

.header-logo-bar a:hover {
  opacity: .7;
}

.header-logo-bar a img {
  margin: auto;
  opacity: 1;
}

.header-logo-bar .col-xs-6 {
  animation: anim-logo-bar-items 800ms linear both;
  opacity: 0;
}

.header-logo-bar .col-xs-6:nth-child(1) {
  animation-delay: .3s;
}

.header-logo-bar .col-xs-6:nth-child(2) {
  animation-delay: .35s;
}

.header-logo-bar .col-xs-6:nth-child(3) {
  animation-delay: .40s;
}

.header-logo-bar .col-xs-6:nth-child(4) {
  animation-delay: .45s;
}

.header-logo-bar .col-xs-6:nth-child(5) {
  animation-delay: .50s;
}

.header-logo-bar .col-xs-6:nth-child(6) {
  animation-delay: .55s;
}

@keyframes anim-logo-bar-items {
  0% {
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  3.4% {
    transform: matrix3d(0.658, 0, 0, 0, 0, 0.703, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    transform: matrix3d(0.725, 0, 0, 0, 0, 0.8, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  6.81% {
    transform: matrix3d(0.83, 0, 0, 0, 0, 0.946, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    transform: matrix3d(0.942, 0, 0, 0, 0, 1.084, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  10.21% {
    transform: matrix3d(0.971, 0, 0, 0, 0, 1.113, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  13.61% {
    transform: matrix3d(1.062, 0, 0, 0, 0, 1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    transform: matrix3d(1.07, 0, 0, 0, 0, 1.165, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
  17.52% {
    transform: matrix3d(1.104, 0, 0, 0, 0, 1.12, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    transform: matrix3d(1.106, 0, 0, 0, 0, 1.094, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  21.32% {
    transform: matrix3d(1.098, 0, 0, 0, 0, 1.035, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    transform: matrix3d(1.075, 0, 0, 0, 0, 0.98, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  25.23% {
    transform: matrix3d(1.067, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.03% {
    transform: matrix3d(1.031, 0, 0, 0, 0, 0.948, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    transform: matrix3d(1.024, 0, 0, 0, 0, 0.949, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    transform: matrix3d(0.99, 0, 0, 0, 0, 0.981, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  36.74% {
    transform: matrix3d(0.986, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    transform: matrix3d(0.98, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  44.44% {
    transform: matrix3d(0.983, 0, 0, 0, 0, 1.016, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    transform: matrix3d(0.996, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  59.86% {
    transform: matrix3d(1.003, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    transform: matrix3d(1.004, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  75.28% {
    transform: matrix3d(1.001, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  90.69% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}

.large-title {
  text-transform: none;
  font-weight: 700;
  padding-top: 40px;
  padding-bottom: 120px;
  font-size: 36px;
  position: relative;
  z-index: 99;
}

@media (max-width: 440px) {
  #header-media {
    display: none;
  }
  #header .col-xs-4 {
    width: 100%;
    text-align: center;
  }
  #slogan {
    padding-bottom: 140px;
  }
  #header-gear {
    bottom: -600px;
    left: -100px;
    height: 450px;
  }
  .large-title {
    padding-bottom: 80px;
    text-align: center;
  }
}

@media (min-width: 440px) and (max-width: 540px) {
  .header-logo-bar {
    padding: 0 0 20px 0;
  }
  .large-title {
    padding-bottom: 120px;
  }
  #header-media {
    background-size: 101%,130%;
    margin: 0 15px;
    height: 320px;
    width: 650px;
  }
  #header-gear {
    bottom: -545px;
    left: -170px;
    height: 450px;
  }
}

@media (min-width: 540px) {
  .header-logo-bar {
    padding: 0 0 20px 0;
  }
  .large-title {
    padding-bottom: 120px;
  }
  #header-media {
    background-size: 101%,110%;
    margin: 0 15px;
    height: 320px;
    width: 650px;
  }
  #header-gear {
    bottom: -620px;
    left: -170px;
    height: 450px;
  }
  #slogan {
    padding-bottom: 20px !important;
  }
}

@media (min-width: 768px) {
  .header-logo-bar {
    padding: 0 0 20px 0;
  }
  .large-title {
    padding-bottom: 80px;
  }
  #header-media {
    background-size: 101%,120%;
    margin: 0 15px;
    height: 450px;
    width: 900px;
  }
  #header-gear {
    bottom: -620px;
    left: -170px;
    width: 650px;
    height: 650px;
  }
  #slogan {
    padding-bottom: 120px !important;
  }
}

@media (min-width: 992px) {
  .header-logo-bar {
    padding: 0 0 20px 0;
  }
  .header-logo-bar br {
    display: none;
  }
  .large-title {
    padding-bottom: 120px;
  }
  #header-media {
    background-size: 101%,100%;
    margin: 0 15px;
    height: 450px;
  }
  #header-gear {
    bottom: -545px;
    left: -170px;
    width: 650px;
    height: 650px;
  }
}

@media (min-width: 1200px) {
  .header-logo-bar {
    padding: 0 0 40px 0;
  }
  #header-media {
    background-size: 105%,90%;
    background-position: center center, center -160px;
    margin: 0 15px;
    height: 465px;
  }
}

@media (min-width: 1400px) {
  #header-media {
    background-size: 105%,75%;
    background-position: center center, center -160px;
  }
}

@media (min-width: 1600px) {
  #header-media {
    background-size: 105%,60%;
    background-position: center center, center -160px;
  }
}

/* ==========================================================================
   =Sticky
   ========================================================================== */
#sticky {
  background: #4c4c4c;
  transition: background-color 300ms;
  padding: 20px 0 15px 0;
  z-index: 9999;
}

#sticky.is_stuck {
  z-index: 9999;
  position: fixed !important;
  box-shadow: 0 1px 15px #000000;
  background: rgba(76, 76, 76, 0.95);
}

#sticky.is_stuck a {
  display: block;
  opacity: .7;
  transition: opacity .2s;
}

#sticky.is_stuck a:hover {
  opacity: 1;
}

#sticky .sticky-gear-wrap {
  position: relative;
  display: block;
  opacity: .9;
  transition: opacity .2s;
  margin: -20px 0 -15px -55px;
  min-height: 65px;
  width: 260px;
  overflow: hidden;
  z-index: 99;
}

#sticky .sticky-gear-wrap:hover {
  opacity: 1;
}

#sticky .sticky-gear-wrap img {
  width: 100%;
}

#sticky .nav-elements {
  display: block;
}

#sticky a {
  display: none;
  padding: 0 12px;
  opacity: 0;
}

#sticky a.no-spacing {
  padding: 0;
}

#sticky .sticky-mobile-nav {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 36px;
  z-index: 999;
  width: 100%;
}

#sticky .sticky-mobile-nav .icon {
  display: block;
  top: 0;
  right: 45px;
  width: 24px;
  height: 24px;
  padding: 6px;
  position: absolute;
  text-align: center;
  font-size: 24px;
  opacity: .9;
  color: #E6E6E6;
  background: rgba(76, 76, 76, 0.85);
  cursor: pointer;
  border: 1px solid #A8A8A8;
  z-index: 99999;
}

#sticky .sticky-mobile-nav .icon:active, #sticky .sticky-mobile-nav .icon:hover {
  opacity: 1;
}

#sticky .sticky-mobile-nav .icon.menu-visible + ul {
  display: block;
}

#sticky .sticky-mobile-nav ul {
  position: relative;
  z-index: 1;
  display: none;
  width: 100%;
  background: rgba(76, 76, 76, 0.95);
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);
  margin: 19px 0 0 0;
  padding: 10px 0;
}

#sticky .sticky-mobile-nav li {
  list-style-type: none;
  margin-bottom: 1px;
}

#sticky .sticky-mobile-nav a {
  color: #fff;
  display: block;
  opacity: .9;
  padding: 8px 15px;
  cursor: pointer;
  text-align: center;
}

@media (max-width: 768px) {
  #sticky .nav-elements {
    display: none;
  }
  #sticky .sticky-gear-wrap {
    margin-left: auto;
    margin-right: auto;
  }
  #sticky .col-xs-12 {
    width: 100%;
  }
  #sticky .sticky-mobile-nav {
    display: block;
    /* right:-200px; */
  }
  #sticky .sticky-mobile-nav a.icon {
    display: block;
    opacity: 1;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  #sticky .sticky-gear-wrap {
    margin: -20px 0 -15px -95px;
  }
  #sticky .nav-elements a {
    padding: 0;
  }
}

@media (min-width: 1200px) {
  #sticky .sticky-gear-wrap {
    min-height: 90px;
    width: 315px;
  }
}

@keyframes logos_animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* =Content
   ========================================================================== */
#content {
  padding-bottom: 25px;
}

.article {
  min-height: 480px;
}

.content-scroller {
  height: 190px;
  overflow-y: auto;
  text-overflow: clip;
  margin-bottom: 20px;
}

.content-scroller p {
  text-overflow: clip;
}

@media (max-width: 768px) {
  .article {
    text-align: center;
  }
}

@media (min-width: 768px) {
  #content {
    padding-bottom: 70px;
  }
}

/* =Footer
   ========================================================================== */
#footer {
  padding: 250px 0 20px;
  background-color: transparent;
  color: #C9C9C9;
  background-image: url("../../content/gear-grey-dark.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 1400px;
}

.footer-logo {
  opacity: .7;
  transition: opacity .2s;
}

.footer-logo:hover {
  opacity: 1;
}

#footer h1,
#footer h1 a,
#footer h2,
#footer h2 a,
#footer h3,
#footer h3 a,
#footer h4,
#footer h4 a,
#footer h5,
#footer h5 a,
#footer h6,
#footer h6 a {
  color: #F2F2F2;
}

#footer a {
  color: #C9C9C9;
}

#footer a:hover {
  color: #fff;
  text-decoration: underline;
}

#footer li {
  list-style-type: none;
}

@media (max-width: 767px) {
  #footer {
    text-align: center;
    padding-top: 100px;
    background-image: url("../../content/footer-gear-mask.svg");
    background-color: #4C4C4C;
    background-size: 1080px;
    transition: background-size .3s;
  }
  #footer-widget-area-1,
  #footer-widget-area-2,
  #footer-widget-area-3,
  #footer-widget-area-4 {
    margin-bottom: 25px;
  }
}

@media (max-width: 600px) {
  #footer {
    background-size: 790px;
  }
}

@media (max-width: 440px) {
  #footer {
    background-size: 660px;
  }
}

@media (max-width: 320px) {
  #footer {
    background-size: 440px;
  }
}

/* ==========================================================================
   =Language switcher
   ========================================================================== */
#language-switcher {
  background-color: #ffbe00;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
}

#language-switcher a {
  color: #4c4c4c;
  font-style: normal;
  display: inline-block;
  line-height: 1;
}

#language-switcher a.selected-language {
  font-weight: 700;
}

#language-switcher a:not(:last-child) {
  border-right: 1px solid #4c4c4c;
  padding-right: 5px;
  margin-right: 5px;
}

@media (max-width: 767px) {
  #language-switcher {
    left: 15px;
  }
}

/* ==========================================================================
   =Logo
   ========================================================================== */
#logo {
  padding: 60px 0 45px;
}

/* ==========================================================================
   =Slogan
   ========================================================================== */
#slogan {
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  padding-bottom: 120px;
  margin-right: -20px;
}

@media (max-width: 440px) {
  padding-bottom: 40px !important;
}

@media (max-width: 767px) {
  #slogan {
    padding-bottom: 50px;
  }
}

/* ==========================================================================
   =Navigation 
   ========================================================================== */
/* #header nav { */
/* float: right; */
/* }	 */
/* ==========================================================================
   =Back to top
   ========================================================================== */
#back-to-top {
  position: fixed;
  z-index: 1010;
  right: -40px;
  bottom: 14px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
  z-index: 99999;
  cursor: pointer;
  transition: all 0.2s ease 0s;
}

#back-to-top::before {
  content: "";
  width: 35px;
  height: 35px;
  background-image: url("../../content/gear-orange.svg");
  -webkit-animation: anim-back-to-top-gear-rotation 4s infinite linear;
  background-size: cover;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 9;
}

#back-to-top i {
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  margin-top: 10px;
  display: block;
  position: relative;
  z-index: 20;
}

@keyframes anim-back-to-top-gear-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}

#back-to-top:hover i {
  color: #4c4c4c;
}

#back-to-top.visible {
  right: 14px;
  transform: rotate(-180deg);
}

#back-to-top.gone {
  right: -40px;
}

/* ==========================================================================
   =Pages
   ========================================================================== */
/* =Grid
   ========================================================================== */
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
  box-sizing: border-box;
}

.row:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
}

.col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
}

/* =Margin
   ========================================================================== */
.ewf-margin-top--none {
  margin-top: 0;
}

.ewf-margin-right--none {
  margin-right: 0;
}

.ewf-margin-bottom--none {
  margin-bottom: 0;
}

.ewf-margin-left--none {
  margin-left: 0;
}

.ewf-margin-vertical--none {
  margin-top: 0;
  margin-bottom: 0;
}

.ewf-margin-horizontal--none {
  margin-right: 0;
  margin-left: 0;
}

.ewf-margin-around--none {
  margin: 0;
}

.ewf-margin-top--xx-small {
  margin-top: 12.5px;
}

.ewf-margin-right--xx-small {
  margin-right: 12.5px;
}

.ewf-margin-bottom--xx-small {
  margin-bottom: 12.5px;
}

.ewf-margin-left--xx-small {
  margin-left: 12.5px;
}

.ewf-margin-vertical--xx-small {
  margin-top: 12.5px;
  margin-bottom: 12.5px;
}

.ewf-margin-horizontal--xx-small {
  margin-right: 12.5px;
  margin-left: 12.5px;
}

.ewf-margin-around--xx-small {
  margin: 12.5px;
}

.ewf-margin-top--x-small {
  margin-top: 17.5px;
}

.ewf-margin-right--x-small {
  margin-right: 17.5px;
}

.ewf-margin-bottom--x-small {
  margin-bottom: 17.5px;
}

.ewf-margin-left--x-small {
  margin-left: 17.5px;
}

.ewf-margin-vertical--x-small {
  margin-top: 17.5px;
  margin-bottom: 17.5px;
}

.ewf-margin-horizontal--x-small {
  margin-right: 17.5px;
  margin-left: 17.5px;
}

.ewf-margin-around--x-small {
  margin: 17.5px;
}

.ewf-margin-top--small {
  margin-top: 35px;
}

.ewf-margin-right--small {
  margin-right: 35px;
}

.ewf-margin-bottom--small {
  margin-bottom: 35px;
}

.ewf-margin-left--small {
  margin-left: 35px;
}

.ewf-margin-vertical--small {
  margin-top: 35px;
  margin-bottom: 35px;
}

.ewf-margin-horizontal--small {
  margin-right: 35px;
  margin-left: 35px;
}

.ewf-margin-around--small {
  margin: 35px;
}

.ewf-margin-top--medium {
  margin-top: 70px;
}

.ewf-margin-right--medium {
  margin-right: 70px;
}

.ewf-margin-bottom--medium {
  margin-bottom: 70px;
}

.ewf-margin-left--medium {
  margin-left: 70px;
}

.ewf-margin-vertical--medium {
  margin-top: 70px;
  margin-bottom: 70px;
}

.ewf-margin-horizontal--medium {
  margin-right: 70px;
  margin-left: 70px;
}

.ewf-margin-around--medium {
  margin: 70px;
}

.ewf-margin-top--large {
  margin-top: 105px;
}

.ewf-margin-right--large {
  margin-right: 105px;
}

.ewf-margin-bottom--large {
  margin-bottom: 105px;
}

.ewf-margin-left--large {
  margin-left: 105px;
}

.ewf-margin-vertical--large {
  margin-top: 105px;
  margin-bottom: 105px;
}

.ewf-margin-horizontal--large {
  margin-right: 105px;
  margin-left: 105px;
}

.ewf-margin-around--large {
  margin: 105px;
}

.ewf-margin-top--x-large {
  margin-top: 140px;
}

.ewf-margin-right--x-large {
  margin-right: 140px;
}

.ewf-margin-bottom--x-large {
  margin-bottom: 140px;
}

.ewf-margin-left--x-large {
  margin-left: 140px;
}

.ewf-margin-vertical--x-large {
  margin-top: 140px;
  margin-bottom: 140px;
}

.ewf-margin-horizontal--x-large {
  margin-right: 140px;
  margin-left: 140px;
}

.ewf-margin-around--x-large {
  margin: 140px;
}

.ewf-margin-top--xx-large {
  margin-top: 175px;
}

.ewf-margin-right--xx-large {
  margin-right: 175px;
}

.ewf-margin-bottom--xx-large {
  margin-bottom: 175px;
}

.ewf-margin-left--xx-large {
  margin-left: 175px;
}

.ewf-margin-vertical--xx-large {
  margin-top: 175px;
  margin-bottom: 175px;
}

.ewf-margin-horizontal--xx-large {
  margin-right: 175px;
  margin-left: 175px;
}

.ewf-margin-around--xx-large {
  margin: 175px;
}

/* =Scrollable
   ========================================================================== */
.ewf-scrollable {
  overflow: auto;
}

.ewf-scrollable--none {
  overflow: hidden;
}

.ewf-scrollable--y {
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.ewf-scrollable--x {
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
}

/* =Padding
   ========================================================================== */
.ewf-padding-top--none {
  padding-top: 0 !important;
}

.ewf-padding-right--none {
  padding-right: 0 !important;
}

.ewf-padding-bottom--none {
  padding-bottom: 0 !important;
}

.ewf-padding-left--none {
  padding-left: 0 !important;
}

.ewf-padding-vertical--none {
  padding-top: 0;
  padding-bottom: 0;
}

.ewf-padding-horizontal--none {
  padding-right: 0;
  padding-left: 0;
}

.ewf-padding-around--none {
  padding: 0;
}

.ewf-padding-top--xx-small {
  padding-top: 12.5px;
}

.ewf-padding-right--xx-small {
  padding-right: 12.5px;
}

.ewf-padding-bottom--xx-small {
  padding-bottom: 12.5px;
}

.ewf-padding-left--xx-small {
  padding-left: 12.5px;
}

.ewf-padding-vertical--xx-small {
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}

.ewf-padding-horizontal--xx-small {
  padding-right: 12.5px;
  padding-left: 12.5px;
}

.ewf-padding-around--xx-small {
  padding: 12.5px;
}

.ewf-padding-top--x-small {
  padding-top: 17.5px;
}

.ewf-padding-right--x-small {
  padding-right: 17.5px;
}

.ewf-padding-bottom--x-small {
  padding-bottom: 17.5px;
}

.ewf-padding-left--x-small {
  padding-left: 17.5px;
}

.ewf-padding-vertical--x-small {
  padding-top: 17.5px;
  padding-bottom: 17.5px;
}

.ewf-padding-horizontal--x-small {
  padding-right: 17.5px;
  padding-left: 17.5px;
}

.ewf-padding-around--x-small {
  padding: 17.5px;
}

.ewf-padding-top--small {
  padding-top: 35px;
}

.ewf-padding-right--small {
  padding-right: 35px;
}

.ewf-padding-bottom--small {
  padding-bottom: 35px;
}

.ewf-padding-left--small {
  padding-left: 35px;
}

.ewf-padding-vertical--small {
  padding-top: 35px;
  padding-bottom: 35px;
}

.ewf-padding-horizontal--small {
  padding-right: 35px;
  padding-left: 35px;
}

.ewf-padding-around--small {
  padding: 35px;
}

.ewf-padding-top--medium {
  padding-top: 70px;
}

.ewf-padding-right--medium {
  padding-right: 70px;
}

.ewf-padding-bottom--medium {
  padding-bottom: 70px;
}

.ewf-padding-left--medium {
  padding-left: 70px;
}

.ewf-padding-vertical--medium {
  padding-top: 70px;
  padding-bottom: 70px;
}

.ewf-padding-horizontal--medium {
  padding-right: 70px;
  padding-left: 70px;
}

.ewf-padding-around--medium {
  padding: 70px;
}

.ewf-padding-top--large {
  padding-top: 105px;
}

.ewf-padding-right--large {
  padding-right: 105px;
}

.ewf-padding-bottom--large {
  padding-bottom: 105px;
}

.ewf-padding-left--large {
  padding-left: 105px;
}

.ewf-padding-vertical--large {
  padding-top: 105px;
  padding-bottom: 105px;
}

.ewf-padding-horizontal--large {
  padding-right: 105px;
  padding-left: 105px;
}

.ewf-padding-around--large {
  padding: 105px;
}

.ewf-padding-top--x-large {
  padding-top: 140px;
}

.ewf-padding-right--x-large {
  padding-right: 140px;
}

.ewf-padding-bottom--x-large {
  padding-bottom: 140px;
}

.ewf-padding-left--x-large {
  padding-left: 140px;
}

.ewf-padding-vertical--x-large {
  padding-top: 140px;
  padding-bottom: 140px;
}

.ewf-padding-horizontal--x-large {
  padding-right: 140px;
  padding-left: 140px;
}

.ewf-padding-around--x-large {
  padding: 140px;
}

.ewf-padding-top--xx-large {
  padding-top: 175px;
}

.ewf-padding-right--xx-large {
  padding-right: 175px;
}

.ewf-padding-bottom--xx-large {
  padding-bottom: 175px;
}

.ewf-padding-left--xx-large {
  padding-left: 175px;
}

.ewf-padding-vertical--xx-large {
  padding-top: 175px;
  padding-bottom: 175px;
}

.ewf-padding-horizontal--xx-large {
  padding-right: 175px;
  padding-left: 175px;
}

.ewf-padding-around--xx-large {
  padding: 175px;
}

/* =Position
   ========================================================================== */
.ewf-is-static {
  position: static;
}

.ewf-is-relative {
  position: relative;
}

.ewf-is-fixed {
  position: fixed;
}

.ewf-is-absolute {
  position: absolute;
}

/* =Text
   ========================================================================== */
.ewf-text-align--left {
  text-align: left;
}

.ewf-text-align--center {
  text-align: center;
}

.ewf-text-align--right {
  text-align: right;
}

.ewf-text-transform--uppercase {
  text-transform: uppercase;
}

/* =Truncation
   ========================================================================== */
.ewf-truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ewf-truncate--25 {
  max-width: 25%;
}

.ewf-truncate--50 {
  max-width: 50%;
}

.ewf-truncate--75 {
  max-width: 75%;
}

/* =Visibility
   ========================================================================== */
@media (min-width: 768px) {
  .ewf-valign--top {
    display: flex;
    align-items: flex-start;
  }
  .ewf-valign--center {
    display: flex;
    align-items: center;
  }
  .ewf-valign--bottom {
    display: flex;
    align-items: flex-end;
  }
}

/* =Visibility
   ========================================================================== */
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
